<template>
  <div class=" mt-2">
    <!-- Table Container Card -->
    <b-breadcrumb class="breadcrumb-pipes">
      <b-breadcrumb-item href="/settings/admin-panel/">
          Admin Panel
        </b-breadcrumb-item>
        <b-breadcrumb-item href="/settings/user-role">
          Roles and Permissions
        </b-breadcrumb-item>
        <b-breadcrumb-item href="/settings/personal-settings">
          Personal Settings
        </b-breadcrumb-item>
      </b-breadcrumb>

    <b-card class="mb-0" :title="$t('settings.action_list.titles.actions_list')">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <b-col
              cols="12"
              md="7"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>{{ $t('settings.action_list.text.show') }} </label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label> {{ $t('settings.action_list.text.entries') }} </label>
            </b-col>

            <b-col cols="12" md="5">
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="filter"
                  class="d-inline-block mr-1"
                  cle
                  :placeholder="$t('settings.action_list.text.search')"
                />
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          striped
          hover
          ref="refActionListTable"
          class="position-relative"
          :items="actionListDatas"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          select-mode="single"
          responsive
          bordered
          :fixed="true"
          :small="false"
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          :empty-text="$t('general_messages.no_matching_recors_found')"
          :current-page="currentPage"
          :per-page="perPage"
        >

          <!-- Column: Action -->
          <template #cell(Action)="data">
            <span class="font-weight-bold">
                  {{ data.item.Action ? data.item.Action : "N/A" }}
                </span>
          </template>

          <!-- Column: User -->
          <template #cell(UserName)="data">
            <span class="font-weight-bold">
                  {{ data.item.UserName ? data.item.UserName : "N/A" }}
                </span>
          </template>

          <!-- Column: date -->
          <template #cell(ActionTime)="data">
            <span class="font-weight-bold">
                  {{ data.item.ActionTime ? data.item.ActionTime : "N/A" }}
                </span>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
            <span class="text-muted"
                >{{$t('general_text.showing')}} {{ dataMeta.from }} {{$t('general_text.to')}} {{ dataMeta.to }} {{$t('general_text.of')}}
                {{ dataMeta.of }} {{$t('general_text.entries')}}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../../settingsStoreModule";
import vSelect from "vue-select";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import { BBreadcrumb, BBreadcrumbItem } from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    BBreadcrumb, BBreadcrumbItem
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      actionListDatas: [],

      filterOn: ["Action", "UserName"],
      tableColumns: [
        { key: "Action", label:this.$t('settings.action_list.table.actions'), sortable: true, class: "text-left", thStyle: { width: "50%" },},
        { key: "UserName", label: this.$t('settings.action_list.table.user'), sortable: true, class: "text-left", thStyle: { width: "30%" } },
        { key: "ActionTime", label: this.$t('settings.action_list.table.timeline'), sortable: true, class: "text-left", thStyle: { width: "20%" } },
      ],
      perPage: localStorage.getItem("userShow") ?? 25,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [25,50, 100],
      filter: null,
      loading: false,
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters([
      'authUser',
    ]),
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  mounted() {
    this.getActions();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getActions() {
      this.loading = true;
      store
        .dispatch("settings/fetcActionList", this.authUser)
        .then((res) => {
          this.actionListDatas = res.data;
          this.totalRows = res.data.length;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
